import { computed, reactive, readonly } from 'vue';
import moment from 'moment';

import { Api } from '@/services/api.service';

const state = reactive({
  latestCourse: {
    course: null,
    instructors: [],
    beingBookmarked: false,
    isLoading: true,
  },
  insiderContent: {
    content: null,
    isLoading: true,
  },
  communityContent: {
    baseUrl: 'https://community.kelbyone.com',
    topics: null,
    categories: null,
    isLoading: true,
  },
  featuredDiscount: {
    discount: null,
    isLoading: true,
  },
  featuredTrack: {
    track: null,
    courses: 0,
    lessons: 0,
    isLoading: true,
  },
  latestPublication: {
    publication: null,
    isLoading: true,
  },
  featuredToolkit: {
    toolkit: null,
    isLoading: true,
  },
  learningTracks: {
    tracks: null,
    isLoading: true,
  },
  courseCategories: {
    categories: null,
    isLoading: true,
  },
  member: null,
});

const headers = new Headers();
headers.append('Accept', 'application/json');
headers.append('Content-Type', 'application/json');

const latestCourse = async (userId, userToken, config) => {
  const response = await Api.get(`${config.api.uri}/${config.api.version}/courses?&per_page=1&include=instructors,lessons,categories`,
    headers);
  if (response.status !== 'error') {
    state.latestCourse.course = response.data.data[0];
    state.latestCourse.course.instructors.forEach(instructor => {
      state.latestCourse.instructors.push(instructor);
    });
    state.latestCourse.isLoading = false;
    if (config.debug) {
      console.log('Providers.Dashboard:latestCourse():response data', response);
    }
  }
};

const latestPublication = async (userId, userToken, config) => {
  const response = await Api.get(`${config.api.uri}/${config.api.version}/publications?user=${userId}&token=${userToken}&per_page=1&include=publication`,
    headers);
  if (response.status !== 'error') {
    state.latestPublication.publication = response.data.data[0];
    state.latestPublication.isLoading = false;
    if (config.debug) {
      console.log('Providers.Dashboard:latestPublication():response data', response);
    }
    return state.latestPublication;
  }
};

const featuredDiscount = async (userId, userToken, config) => {
  const response = await Api.get(`${config.api.uri}/${config.api.version}/discounts?&per_page=1&orderby=featured`,
    headers);
  if (response.status !== 'error') {
    state.featuredDiscount.discount = response.data.data[0];
    state.featuredDiscount.isLoading = false;
    if (config.debug) {
      console.log('Providers.Dashboard:featuredDiscount():response data', response);
    }
  }
};

const featuredTrack = async (userId, userToken, config) => {
  const response = await Api.get(`${config.api.uri}/${config.api.version}/tracks?per_page=1&include=courses&orderby=rand&now=${Date.now()}`,
    headers);
  if (response.status !== 'error') {
    state.featuredTrack.track = response.data.data[0];
    state.featuredTrack.courses = response.data.data[0].courses.length;
    state.featuredTrack.track.courses.forEach(course => {
      state.featuredTrack.lessons += course.lessons.length;
    });
    state.featuredTrack.isLoading = false;
    if (config.debug) {
      console.log('Providers.Dashboard:featuredTrack():response data', response);
    }
  }
};

const learningTracks = async (config) => {
  console.log('Providers.Dashboard:learningTracks():initiated');
  const response = await Api.get(`${config.api.uri}/${config.api.version}/tracks?per_page=12&orderby=featured`,
    headers);
  if (response.status !== 'error') {
    state.learningTracks.tracks = response.data.data;
    state.learningTracks.isLoading = false;
    if (config.debug) {
      console.log('Providers.Dashboard:learningTracks():response data', response);
    }
  }
};

const courseCategories = async (config) => {
  console.log('Providers.Dashboard:courseCategories():initiated');
  const response = await Api.get(`${config.api.uri}/${config.api.version}/categories`,
    headers);
  if (response.status !== 'error') {
    state.courseCategories.categories = response.data.data;
    state.courseCategories.isLoading = false;
    if (config.debug) {
      console.log('Providers.Dashboard:courseCategories():response data', response);
    }
  }
};

const featuredToolkit = async (userId, userToken, config) => {
  let date = moment();
  let previousDate = date.subtract(2, 'years').format();

  const response = await Api.get(`${config.api.uri}/${config.api.version}/toolkits?user=${userId}&token=${userToken}&orderby=rand&per_page=1&date[created][after]=${previousDate}&filter[toolkit_type]=download`,
    headers);
  if (response.status !== 'error') {
    state.featuredToolkit.toolkit = response.data.data[0];
    state.featuredToolkit.isLoading = false;
    if (config.debug) {
      console.log('Providers.Dashboard:featuredToolkit():response data', response);
    }
    return state.featuredToolkit;
  }
};

const insiderContent = async (config) => {
  let insiderContent = [];
  const responseOne = await Api.get(
    'https://insider.kelbyone.com/wp-json/wp/v2/posts?per_page=1&tags=319&_embed',
    headers);
  if (responseOne.status !== 'errror') {
    insiderContent.push(responseOne.data[0]);
    if (config.debug) {
      console.log('Providers.Dashboard:insiderContentFirstCall():response data', responseOne);
    }
  }
  const responseTwo = await Api.get(`https://insider.kelbyone.com/wp-json/wp/v2/posts?per_page=1&categories_exclude=53,77,441&exclude_tags=319&exclude=${insiderContent[0].id}&_embed`,
    headers);
  if (responseTwo.status !== 'errror') {
    insiderContent.push(responseTwo.data[0]);
    state.insiderContent.content = insiderContent;
    state.insiderContent.isLoading = false;
    if (config.debug) {
      console.log('Providers.Dashboard:insiderContentSecondCall():response data', responseTwo);
    }
  }
};

const communityContent = async (config) => {
  const responseOne = await Api.get(`${config.api.uri}/${config.api.version}/discourse/categories`,
    headers);
  if (responseOne.status !== 'error') {
    state.communityContent.categories = responseOne.data.data;
    if (config.debug) {
      console.log('Providers.Dashboard:communityContent() - CategroiesCall :response data',
        responseOne);
    }
  }
  const responseTwo = await Api.get(`${config.api.uri}/${config.api.version}/discourse/latest`,
    headers);
  if (responseTwo.status !== 'error') {
    state.communityContent.topics = responseTwo.data.data.slice(1, 8);
    state.communityContent.topics = state.communityContent.topics.map(topic => {
      topic.category = getCategory(topic.category_id);
      return topic;
    });
    state.communityContent.isLoading = false;
    if (config.debug) {
      console.log('Providers.Dashboard:communityContent() - TopicsCall :response data',
        responseOne);
    }
    return state.communityContent;
  }
};

const getCategory = (categoryId) => {
  let match = state.communityContent.categories.find(category => category.id === categoryId);
  if (match) {
    return match;
  }
  return state.communityContent.categories.find(category => category.id === 1);
};

const dashboardData = async (userId, token, config) => {
  await learningTracks(config);
  await courseCategories(config);
  await latestCourse(userId, token, config);
  await insiderContent(config);
  await communityContent(config);
  await featuredDiscount(userId, token, config);
  await featuredTrack(userId, token, config);
  await latestPublication(userId, token, config);
  await featuredToolkit(userId, token, config);
  return state;
};

const loadMember = async (user, config, authProvider, isUpdating) => {
  if (isUpdating || !state.member) {
    let response = await authProvider().loadUser(user.id, user.token, config);
    if (response.status !== 'error') {
      state.member = response.data.data;
      if (config.debug) {
        console.log('Providers.Dashboard:loadMember():response data', response);
      }
    }
  }
};

export default function dashboardProvider() {
  return readonly({
    latestCourse,
    latestPublication,
    featuredDiscount,
    featuredTrack,
    featuredToolkit,
    insiderContent,
    communityContent,
    dashboardData,
    loadMember,
    learningTracks,
    courseCategories,
    getMember: computed(() => state.member),
    hasMember: computed(() => Boolean(state.member)),
    getCourse: computed(() => state.latestCourse.course),
    hasCourse: computed(() => Boolean(state.latestCourse.course)),
    getInsider: computed(() => state.insiderContent.content),
    hasInsider: computed(() => Boolean(state.insiderContent.content)),
    getCommunity: computed(() => state.communityContent.topics),
    hasCommunity: computed(() => Boolean(state.communityContent.topics)),
    getDiscount: computed(() => state.featuredDiscount.discount),
    hasDiscount: computed(() => Boolean(state.featuredDiscount.discount)),
    getTrack: computed(() => state.featuredTrack.track),
    hasTrack: computed(() => Boolean(state.featuredTrack.track)),
    getTrackCourseCount: computed(() => state.featuredTrack.courses),
    getTrackLessonCount: computed(() => state.featuredTrack.lessons),
    getPublication: computed(() => state.latestPublication.publication),
    hasPublication: computed(() => Boolean(state.latestPublication.publication)),
    getToolkit: computed(() => state.featuredToolkit.toolkit),
    hasToolkit: computed(() => Boolean(state.featuredToolkit.toolkit)),
    getLearningTracks: computed(() => state.learningTracks.tracks),
    hasLearningTracks: computed(() => Boolean(state.learningTracks.tracks)),
    getCourseCategories: computed(() => state.courseCategories.categories),
    hasCourseCategories: computed(() => Boolean(state.courseCategories.categories)),
  });
}
