import { reactive, readonly, computed } from 'vue'

import { Api } from '@/services/api.service'

const state = reactive({
  member: null, 
});


const headers = new Headers();
headers.append('Accept', 'application/json');
headers.append('Content-Type', 'application/json');


const loadMember = async (user, config, authProvider) => {
  let response = await authProvider().loadUser(user.id, user.token, config);
  if (response.status !== 'error') {
    state.member = response.data.data;
    if (config.debug) {
      console.log('Providers.Collections:loadMember():response data', response)
    }  
  }
}

export default function collectionsProvider () {
  return {
    loadMember,
    state,
  };
}
