import { readonly } from 'vue';

/**
 * @memberof Providers.Analytics
 * @instance
 * @summary Handles all analytics tracking.
 * @param { object } data - The tracking data.
 * @param { Config } config - The component configuration.
 * @returns { void }
 */
export const Analytics = {
  track: async (provider, event, tracking, config) => {
    if (config.debug) {
      console.log('Services.Analytics:track():submitted data', { provider, event, tracking, config });
    }

    if (config.analytics) {
      if ('gtm' === provider) {
        if (config.debug) {
          console.log('Services.Analytics:track():gtm tracking data', config.analytics.gtm, `${event}`, tracking);
        }
        return await dataLayer.push(tracking);
      }
      if ('ga' === provider) {
        if (config.debug) {
          console.log('Services.Analytics:track():ga tracking data', config.analytics.ga, `${event}`, tracking);
        }
        return await ga('send', tracking);
      }
      if ('fbq' === provider) {
        if (config.debug) {
          console.log('Services.Analytics:track():fbq tracking data', config.analytics.fbq, `${event}`, tracking);
        }
        return await fbq('track', `${event}`, tracking);
      }
    }
  }
}
