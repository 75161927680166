import { reactive, ref, computed} from 'vue'
import { Api } from '@/services/api.service'

const state = reactive({
  sections: {},
  member: null,
});


const headers = new Headers();
headers.append('Accept', 'application/json');
headers.append('Content-Type', 'application/json');

const loadMember = async (user, config, authProvider, isUpdating) => {
  if(isUpdating || !state.member) {
    let response = await authProvider().loadUser(user.id, user.token, config);
    if (response.status !== 'error') {
      state.member = response.data.data;
      if (config.debug) {
        console.log('Providers.Sections:loadMember():response data', response)
      }
    }
  }
}

const loadSectionData = async (user, section) => {
  state.sections[section.title] = section;
  const url = new URL(section.endpoint);
  url.searchParams.append('token', user.token);
  url.searchParams.append('per_page', section.per_page);
  url.searchParams.append('include', "instructors,lessons");
  const response = await Api.get(url.href, headers);
  if (response.status !== 'error') {
    state.sections[section.title].results = response.data.data;
  }
}

const reloadSection = async (user, sectionTitle) => {
  const section = state.sections[sectionTitle];
  if(section) {
    await loadSectionData(user, section);
  }
}

export default function sectionsProvider () {
  return {
    loadSectionData,
    reloadSection,
    loadMember,
    state,
  };
}

