import { computed, reactive, readonly, ref } from 'vue'
import { Api } from '@/services/api.service'

const state = reactive({
  results: ref([]),
  availableFilters: ref([]),
  activeFilters: ref([]),
  pagination: ref(null),
  sortByFilter: ref(null),
  params: ref({
    // page: '',
    // per_page: '',
    // 'filter[type]': '',
  }),
})

const updateFilters = async (response) => {
  const { meta } = response.data
  state.availableFilters = meta.filters
  if (state.availableFilters) {
    for (const option of state.availableFilters) {
      if (option.active) {
        const active = option.data.filter(
          taxonomy => taxonomy.active === true)
        const match = state.activeFilters.filter(
          tax => tax.label === option.label)
        if (match.length) {
          match[0].active = active
        }
        else {
          const taxonomy = {
            label: option.label,
            active,
          }
          state.activeFilters.push(taxonomy)
        }
      }
    }
  }
  return state.filters
}

const toggleParam = async (key, value, config) => {
  loadResults()
}

const buildUrl = () => {
  return Object.keys(state.params).map(
    key => key + '=' + state.params[key]).join('&')
}

const loadResults = async (queryString, config, user = null) => {

  let query = queryString || await buildUrl()

  let url = `${config.api.uri}/${config.api.version}/advanced_search?per_page=4`
  if (query) {
    let string
    if (query.includes('filter[type]')) {
      if (query.includes('per_page')) {
        const regex = 'per_page=\\d{1,}'
        const exists = query.match(regex)
        if (exists) {
          string = query.replace(exists[0], 'per_page=12')
        }
      }
      else {
        string = query.length ? `${query}&per_page=12` : 'per_page=12'
      }
    }
    else {
      if (query.includes('per_page')) {
        const regex = 'per_page=\\d{1,}'
        const exists = query.match(regex)
        if (exists) {
          string = query.replace(exists[0], 'per_page=4')
        }
      }
      else {
        string = query.length ? `${query}&per_page=4` : 'per_page=4'
      }
    }
    query = string
    url = `${config.api.uri}/${config.api.version}/advanced_search?${query}`
  }
  else {
    url = `${config.api.uri}/${config.api.version}/advanced_search?per_page=4`
  }
  if(user && user.token) {
    url = `${url}&token=${user.token}`
  }

  const headers = new Headers()

  headers.append('Accept', 'application/json')
  headers.append('Content-Type', 'application/json')

  const response = await Api.get(url, headers)

  if (config.debug) {
    console.log('Providers.Search:loadResults():response', response)
  }

  // if (response.status === 'success') {
  window.history.pushState(query, 'KelbyOne Search', `?${query}`)
  state.results = response.data.data
  state.pagination = response.data.meta.pagination
  const { meta } = response.data
  state.activeFilters = []
  state.availableFilters = []
  if (meta.filters) {
    for (const option of meta.filters) {
      if (option.active && option.label !== 'Sort By') {
        const active = option.data.filter(
          taxonomy => taxonomy.active === true)
        const match = state.activeFilters.filter(
          tax => tax.label === option.label)
        if (match.length) {
          match[0].active = active
        }
        else {
          const taxonomy = {
            label: option.label,
            active,
          }
          state.activeFilters.push(taxonomy)
        }
      }
    }

    state.sortByFilter = meta.filters.find( (item) => 'Sort By' === item.label );
    state.availableFilters = meta.filters.filter( (item) => 'Sort By' !== item.label );
    // }
  }
  return response
}

export default function SearchProvider () {
  return readonly({
    toggleParam,
    loadResults,
    // Results
    getResults: computed(() => state.results),
    hasResults: computed(() => Boolean(state.results)),
    // Pagination
    getPagination: computed(() => state.pagination),
    hasPagination: computed(() => Boolean(state.pagination)),
    // Filters
    getAvailableFilters: computed(() => state.availableFilters),
    hasAvailableFilters: computed(() => Boolean(state.availableFilters)),
    getActiveFilters: computed(() => state.activeFilters),
    hasActiveFilters: computed(() => Boolean(state.activeFilters)),
    sortByFilter: computed(() =>state.sortByFilter),
  })
}
