import { computed, reactive, readonly, ref } from 'vue';

import { Api } from '@/services/api.service';

const state = reactive({
  isLoading: ref(false),
  response: ref(null),
});

const setResponse = (response) => {
  state.response = response;
};

const setIsLoading = async (loading) => {
  state.isLoading = loading;
};

const preview = async (couponCode, user, config = {}) => {
  if (config.debug) {
    console.log('Providers.CouponCodes:preview()', {
      couponCode,
      user,
      config,
    });
  }
  if (!config.api.uri) {
    console.warn(
      'Providers.CouponCodes:preview() | Api config is required to retreive customer information.');
  }

  await setIsLoading(true);
  let url = `${config.api.uri}/${config.api.version}/coupons/?coupon=${couponCode}`;
  if (user && user.token) {
    url = `${config.api.uri}/${config.api.version}/coupons/?coupon=${couponCode}&token=${user.token}`;
  }
  const headers = new Headers();

  const response = await Api.get(url, headers);

  if (config.debug) {
    console.log('Providers.CouponCodes:preview():response data', response);
  }

  if (response.status === 'error') {
    setResponse(null);
    await setIsLoading(false);

    return response;
  } else {
    setResponse(response.data.data);
  }

  await setIsLoading(false);

  return response;
};

const redeem = async (coupon_code, user, config = {}) => {
  if (config.debug) {
    console.log('Providers.CouponCodes:redeem()', {
      coupon_code,
      config,
    });
  }
  if (!config.api.uri) {
    console.warn(
      'Providers.CouponCodes:redeem() | Api config is required to retreive customer information.');
  }

  await setIsLoading(true);
  const url = `${config.api.uri}/${config.api.version}/coupons/?coupon=${coupon_code}&token=${user.token}`;
  const headers = new Headers();

  const response = await Api.post(url, headers);

  if (config.debug) {
    console.log('Providers.CouponCodes:redeem():response data', response);
  }

  if (response.status === 'error') {
    setResponse(null);
    await setIsLoading(false);

    return response;
  } else {
    setResponse(response.data.data);
  }

  await setIsLoading(false);

  return response;
};

export default function CouponCodesProvider () {
  return readonly({
    preview,
    redeem,
    setResponse,
    getResponse: computed(() => state.response),
    isLoading: computed(() => Boolean(state.isLoading)),
  });
}
