import { computed, reactive, readonly, ref, } from 'vue';

import { Api } from '@/services/api.service';

const state = reactive({
  instructors: ref(null),
  isLoading: ref(false),
});

const loadInstructors = async (config = {}, event, sort_by, sort_order, order = []) => {
  // Add debug check for console.log of submitted data
  if (config.debug) {
    console.log('Providers.Instructors:loadInstructors():submitted data',
      config,
      { event, sort_by, sort_order, order });
  }

  // Check for required fields and throw error as required
  if (!config.api.uri) {
    return Error(
      'Providers.Instructors:loadInstructors() | Api config is required to retreive instructors.');
  }
  let url = `${config.api.uri}/${config.api.version}/instructors`;

  state.isLoading = true;

  const headers = new Headers();
  headers.append('Accept', 'application/json');
  headers.append('Content-Type', 'application/json');

  let args = {};
  if (event) args.event_id = event;
  if (sort_by) args.sort_by = sort_by;
  if (sort_order) args.sort_order = sort_order;
  if (order.length) args.instructor_order = order;

  const response = await Api.post(url, args, headers);

  state.isLoading = false;

  // Add debug check for console.log of response data
  if (config.debug) {
    console.log('Providers.Instructors:loadInstructors():response data', response);
  }

  // if success set state.instructors
  if ('success' === response.status) {
    state.instructors = response.data.data;
  }

  return response;
}


/**
 * KelbyOne Instructors Provider
 * @namespace Providers.Instructors
 * @ignore
 * @summary KelbyOne Instructors Provider
 * @property { Boolean } isLoading General loading state.
 * @property { Array } getInstructors Returns array of instructors.
 * @property { Boolean } hasInstructors Returns whether instructors array exists.
 */
export default function EventProvider() {
  return readonly({
    loadInstructors,
    // General
    isLoading: computed(() => Boolean(state.isLoading)),
    // Instructors
    hasInstructors: computed(() => Boolean(state.instructors)),
    getInstructors: computed(() => state.instructors),
  });
}
