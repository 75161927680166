import { computed, reactive, readonly, ref } from 'vue'

import { Api } from '@/services/api.service'

const state = reactive({
  customer: ref(null),
  membership: ref(null),
  profile: ref(null),
  profiles: ref(null),
  isLoading: ref(false),
  isCreating: ref(false),
  isUpdating: ref(false),
  currentProfile: ref(null),
})

/**
 * @memberof Providers.Subscription
 * @instance
 * @summary Sets the subscription product state as the provided string.
 * @param { string } product - The product handle string.
 * @returns { void }
 */
const set = (key, value) => {
  state[key] = value
}

/**
 * @memberof Providers.Customer
 * @instance
 * @summary Sets the customer state as the provided object.
 * @param { object } customer - The customer object.
 * @returns { void }
 */
const setCustomer = async (customer = {}, config) => {
  if (config.debug) {
    console.log('Providers.Customer:setCustomer():submitted data', customer)
  }
  await set('customer', customer)
  await set('profiles', customer.profiles)
  const membership = await customer.subscriptions.filter(
    (subscription) => subscription.type === 'membership')[0]
  if (membership) {
    await set('membership', membership.id);
    if(membership.current && membership.current.profile) {
      await set('currentProfile', membership.current.profile.id);
    }
  }
}

/**
 * @memberof Providers.Customer
 * @instance
 * @summary Attempts to load a customer with the provided id and token.
 * @param { string } id - The customer id.
 * @param { string } token - The token.
 * @param { Config } config - The component configuration.
 * @implements {@link Services.Api#fetch|Services.Api.fetch()}
 * @returns { Response }
 * @throws { Error }
 */
const loadCustomer = async (id, token, config) => {
  if (config.debug) {
    console.log('Providers.Customer:loadCustomer():submitted data', {
      id,
      token,
      config,
    })
  }
  if (!config.api.uri) {
    throw Error(
      'Providers.Customer:loadCustomer() | Api config is required to retreive customer information.')
  }
  if (!token) {
    throw Error('Providers.Customer:loadCustomer() | User token is required.')
  }
  if (!id) {
    throw Error('Providers.Customer:loadCustomer() | Customer id is required.')
  }

  state.isLoading = true
  const url = `${config.api.uri}/${config.api.version}/customers/${id}?token=${token}&now=${Date.now()}`
  const response = await Api.fetch(url)
  state.isLoading = false

  if (config.debug) {
    console.log('Providers.Customer:loadCustomer():response data', response)
  }

  if (response.status === 'error') {
    setCustomer(null, config)
    return response
  }

  setCustomer(response.data.data, config)
  return response
}

const createPayment = async (details, token, config) => {
  if (config.debug) {
    console.log('Providers.Customer:createPayment():submitted data', {
      details,
      token,
      config,
    })
  }
  if (!details) {
    throw Error(
      'Providers.Customer:createPayment() | Payment Details are required to create customer payment.')
  }
  if (!token) {
    throw Error(
      'Providers.Customer:createPayment() | Token is required to create customer payment.')
  }
  if (!config.api.uri) {
    throw Error(
      'Providers.Customer:createPayment() | Api config is required to create customer payment.')
  }

  let url = `${config.api.uri}/${config.api.version}/customers/${state.customer.id}/profiles?token=${token}&now=${Date.now()}`

  const headers = new Headers()

  headers.append('Accept', 'application/json')
  headers.append('Content-Type', 'application/json')

  const response = await Api.post(url, details, headers)

  if (config.debug) {
    console.log('Providers.Customer:createPayment():response', response)
  }
  await loadCustomer(state.customer.id, token, config)

  if (response.data.data.redirect &&
    response.data.data.redirect.url) {
    return window.location.replace(
      response.data.data.redirect.url)
  }

  return response
}

const updatePayment = async (details, token, config) => {
  if (config.debug) {
    console.log('Providers.Customer:updatePayment():submitted data', {
      details,
      token,
      config,
    })
  }
  if (!details) {
    throw Error(
      'Providers.Customer:updatePayment() | Payment Details are required to update customer payment.')
  }
  if (!token) {
    throw Error(
      'Providers.Customer:updatePayment() | Token is required to update customer payment.')
  }
  if (!config.api.uri) {
    throw Error(
      'Providers.Customer:updatePayment() | Api config is required to update customer payment.')
  }

  let url = `${config.api.uri}/${config.api.version}/customers/${state.customer.id}/profiles/${details.id}?token=${token}&now=${Date.now()}`
  delete details.id

  const headers = new Headers()

  headers.append('Accept', 'application/json')
  headers.append('Content-Type', 'application/json')

  const response = await Api.patch(url, details, headers)

  if (config.debug) {
    console.log('Providers.Customer:createPayment():response', response)
  }
  await loadCustomer(state.customer.id, token, config)
  return response
}

const removePayment = async (payment, token, config) => {
  if (config.debug) {
    console.log('Providers.Customer:removePayment():submitted data', {
      payment,
      token,
      config,
    })
  }

  let url = `${config.api.uri}/${config.api.version}/customers/${state.customer.id}/profiles/${payment}?token=${token}&now=${Date.now()}`

  const response = await Api.remove(url)
  if (config.debug) {
    console.log('Providers.Customer:removePayment():response', response)
  }
  await loadCustomer(state.customer.id, token, config)
  return response
}

const activatePayment = async (payment) => state.profile = payment


const loadCustomerSubscriptionSummary = async (id, token, subscriptionId, config) => {
  if (config.debug) {
    console.log('Providers.Customer:loadCustomerSubscriptionSummary():submitted data', {
      id,
      token,
      subscriptionId,
      config,
    })
  }

  state.isLoading = true
  const url = `${config.api.uri}/${config.api.version}/customers/${id}/subscriptions/${subscriptionId}/summary?token=${token}&now=${Date.now()}`
  try {
    // Make the API request
    const response = await Api.fetch(url)

    // Log the response if debugging is enabled
    if (config.debug) {
      console.log('Providers.Customer:loadCustomerSubscriptionSummary():response data', response)
    }

    return response;
  } catch (error) {
    console.error('Providers.Customer:loadCustomerSubscriptionSummary() Error fetching data:', error);
    throw error;
  } finally {
    state.isLoading = false
  }

}


/**
 * KelbyOne Customer Provider
 * @namespace Providers.Customer
 * @ignore
 * @summary KelbyOne Customer Provider
 * @property { Boolean } isLoading General loading state.
 * @property { Boolean } isCreating General creating state.
 * @property { Boolean } isUpdating General updating state.
 * @property { Object | null } getCustomer Returns the state of the customer.
 * @property { Boolean } hasCustomer Returns whether a customer exists.
 * @property { Object | null } getCustomerProfiles Returns the state of the
 *   customer payment profiles.
 * @property { Boolean } hasCustomerProfiles Returns whether a customer has
 *   payment profiles.
 * @property { Object | null } getCustomerSubscriptions Returns the state of
 *   the customer subscriptions.
 * @property { Boolean } hasCustomerSubscriptions Returns whether a customer
 *   has subscriptions.
 * @property { Object | null } getCustomerMembership Returns the state of the
 *   customer membership.
 * @property { Boolean } hasCustomerMembership Returns whether a customer has a
 *   KelbyOne membership.
 */
export default function CustomerProvider () {
  return readonly({
    loadCustomer,
    setCustomer,
    createPayment,
    updatePayment,
    removePayment,
    activatePayment,
    loadCustomerSubscriptionSummary,
    // General
    isLoading: computed(() => Boolean(state.isLoading)),
    isCreating: computed(() => Boolean(state.isCreating)),
    isUpdating: computed(() => Boolean(state.isUpdating)),
    // Customer
    getCustomer: computed(() => state.customer),
    hasCustomer: computed(() => Boolean(state.customer)),
    // Default Profile
    getDefaultProfile: computed(() => state.profile),
    hasDefaultProfile: computed(() => Boolean(state.profile)),
    // Profiles
    getProfiles: computed(() => state.profiles),
    hasProfiles: computed(() => Boolean(state.profiles)),
    getCurrentProfile: computed(() => state.currentProfile),
    hasCurrentProfile: computed(() => Boolean(state.currentProfile)),
    // Subscriptions
    getSubscriptions: computed(() => state.subscriptions),
    hasSubscriptions: computed(() => Boolean(
      state.customer && state.customer.subscriptions &&
      state.customer.subscriptions.length)),
    // Membership
    getCustomerMembership: computed(() => state.membership),
    hasCustomerMembership: computed(() => Boolean(state.membership)),
  })
}
