const { warn } = console;

let config;

const getConfig = () => config;

const hasConfigured = () => Boolean(getConfig());

const debug = () => Boolean(config && config.debug);

const configure = (options = {}) => {
  // const { api } = options;
  if (hasConfigured()) {
    warn(
      'Components have already been configured. The following config changes will NOT be applied.',
      options,
    );
  } else {
    config = options;
    if (debug()) console.info('vueKOC configured with values', config);
  }
};

export {
  configure, debug, getConfig, hasConfigured,
};
