/**
 * KelbyOne Api Service
 * @namespace Services.Api
 * @ignore
 * @summary KelbyOne Api Service
 */

const send = async (url, method, headers = {}, body) => fetch(url, { method, headers, body });

export const Api = {
  /**
   * @memberof Services.Api
   * @instance
   * @summary Does a standard FETCH call to the provided URL.
   * @param { string } url - The URL you want to send a GET request to.
   * @param { object } headers - The HEADERS for the request.
   * @async
   * @returns { Response }
   * @throws { Error }
   */
  fetch: async (url) => {
    const response = await fetch(url);
    const data = await response.json();

    if ((response.status !== 200 && response.status !== 201) || response.success === false) {
      return {
        status: 'error',
        data,
      };
    }
    return {
      status: 'success',
      data,
    };
  },

  /**
   * @memberof Services.Api
   * @instance
   * @summary Does a GET call to the provided URL.
   * @param { string } url - The URL you want to send a GET request to.
   * @param { object } headers - The HEADERS for the request.
   * @async
   * @returns { Response }
   * @throws { Error }
   */
  get: async (url, headers = null) => {
    const response = await send(url, 'GET', headers);
    const data = await response.json();

    if (response.status !== 200) {
      return {
        status: 'error',
        data,
      };
    }
    return {
      status: 'success',
      data,
    };
  },

  /**
   * @memberof Services.Api
   * @instance
   * @summary Does a POST call to the provided URL with the provided BODY.
   * @param { string } url - The URL you want to send a POST request to.
   * @param { object } body - The BODY you want to POST.
   * @param { object } headers - The HEADERS for the request.
   * @async
   * @returns { Response }
   * @throws { Error }
   */
  post: async (url, body, headers, raw = false) => {
    // try {
    body = ( raw ) ? body : JSON.stringify(body);

    const response = await send(url, 'POST', headers, body);
    const data = await response.json();

    if ((response.status !== 200 && response.status !== 201) || response.success === false) {
      return {
        status: 'error',
        data,
      };
    }
    return {
      status: 'success',
      data,
    };

    // } catch (error) {
    //   throw new Error('Unable to send POST request', error)
    // }
  },

  /**
   * @memberof Services.Api
   * @instance
   * @summary Does a PATCH call to the provided URL with the provided BODY.
   * @param { string } url - The URL you want to send a PATCH request to.
   * @param { object } body - The BODY you want to PATCH.
   * @param { object } headers - The HEADERS for the request.
   * @async
   * @returns { Response }
   * @throws { Error }
   */
  patch: async (url, body, headers = null) => {
    const response = await send(url, 'PATCH', headers, JSON.stringify(body));
    const data = await response.json();

    if (data.data.redirect && data.data.redirect.url) return window.location.replace(data.data.redirect.url);

    if ((response.status !== 200 && response.status !== 201) || response.success === false) {
      return {
        status: 'error',
        data,
      };
    }
    return {
      status: 'success',
      data,
    };
  },

  /**
   * @memberof Services.Api
   * @instance
   * @summary Does a DELETE call to the provided URL with the provided DATA.
   * @param { string } url - The URL you want to send a DELETE request to.
   * @async
   * @returns { Response }
   * @throws { Error }
   */
  remove: async (url) => {
    const response = await send(url, 'DELETE');
    const data = await response.json();

    if (response.status !== 200) {
      return {
        status: 'error',
        data,
      };
    }
    return {
      status: 'success',
      data,
    };
  },
};
