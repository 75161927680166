import { computed, reactive, readonly, ref } from 'vue';

import { Api } from '@/services/api.service';

const state = reactive({
  user: ref(null),
  cookie: ref(null),
  isLoading: ref(false),
  isCreating: ref(false),
  isUpdating: ref(false),
  purchaseHistory: ref(null),
  mailingAddress: ref(null),
  downloads: ref(null),
  memberships: ref(null),
  memberProfile: ref(null),
  userCompletedLessons: computed(() => {
    if (state.user && state.user.content.length) {
      return state.user.content.filter(item => {
        return item.type === 'lesson' && item.completed !== null;
      }).map(item => {
        return item.id;
      })
    }
    return [];
  }),
})

/**
 * @memberof Providers.Auth
 * @instance
 * @summary Sets the user state as the provided object.
 * @param { object } user - The user object.
 * @returns { void }
 */
const setUser = (user = {}, config) => {
  state.user = user;
  if (user) {
    if (user.preferences && user.preferences.theme) {
      document.dispatchEvent(new CustomEvent('koc-theme-toggle',
        { detail: { theme: user.preferences.theme } }));
    }
    if (config.cookie) {
      setUserCookie(user, config);
    }
  }
};

/**
 * @memberof Providers.Auth
 * @instance
 * @summary Sets browser cookies with the provided user object.
 * @param { object } user - The user object.
 * @param { Config } config - The component configuration.
 * @returns { void }
 */
const setUserCookie = (user, config) => {
  if (config.debug) {
    console.log('Providers.Auth:setUserCookie():submitted data', {
      user,
      config,
    });
  }
  if (!config.cookie) {
    console.warn(
      'Providers.Auth:setUserCookie() | Cookie config is required to set user cookie.');
  }
  if (!user) {
    console.warn(
      'Providers.Auth:setUserCookie() | User is required to set user cookie.');
  }

  const {
    id,
    email,
    token,
    firstName,
    lastName,
    displayName,
  } = user;
  const userData = {
    id,
    email,
    token,
    firstName,
    lastName,
    displayName,
  };
  state.cookie = userData;
  let cookieValue = JSON.stringify(userData);
  let cookieName = config.cookie.name || 'koc-user';
  let expirationDays = config.cookie.expdays || 7;
  let date = new Date();
  date.setTime(date.getTime() + (expirationDays * 24 * 60 * 60 * 1000));
  let expires = `expires=${date.toUTCString()}`;
  document.cookie = `${cookieName}=${cookieValue};${expires};path=/`;
  if (config.debug) {
    console.log('Providers.Auth:setUserCookie():cookie set',
      `${cookieName}=${cookieValue};${expires};path=/`);
  }
};

const loadUserCookie = (config) => {
  if (config.debug) {
    console.log('Providers.Auth:loadUserCookie():submitted data', { config });
  }
  if (!config.cookie) {
    console.warn(
      'Providers.Auth:loadUserCookie() | Cookie config is required to set user cookie.');
  }

  let cookieName = config.cookie.name || 'koc-user';
  let name = `${cookieName}=`;
  let decodedCookie = decodeURIComponent(document.cookie);

  if (!decodedCookie && config.debug) {
    return console.log(
      'Providers.Auth:loadUserCookie() | No cookie was found!');
  }

  let parts = decodedCookie.split(';');

  for (let part of parts) {
    // check for space at first character and remove it if it exists
    if (part.charAt(0) == ' ') {
      part = part.substring(1);
    }

    if (part.startsWith(name)) {
      const cookie = JSON.parse(part.substring(name.length, part.length));
      if (config.debug) {
        console.log(
          `Providers.Auth:loadUserCookie():existing cookie match (${name})!`,
          cookie);
      }
      state.cookie = cookie;
      return cookie;
    }
  }
  return false;
};

const deleteUserCookie = (config) => {
  if (config.debug) {
    console.log('Providers.Auth:deleteUserCookie():submitted data', { config });
  }
  if (!config.cookie) {
    console.warn(
      'Providers.Auth:deleteUserCookie() | Cookie config is required to delete user cookie.');
  }

  let cookieName = config.cookie.name || 'koc-user';
  document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;

  return;
};

/**
 * @memberof Providers.Auth
 * @instance
 * @summary Attempts to login a user with the given username and password.
 * @param { string } username - The username.
 * @param { string } password - The password.
 * @param { Config } config - The component configuration.
 * @implements {@link Services.Api#get|Services.Api.get()}
 * @returns { Response }
 * @throws { Error }
 */
const login = async (username, password, config = {}) => {
  if (config.debug) {
    console.log('Providers.Auth:login()', {
      username,
      password,
      config,
    });
  }
  if (!config.api.uri) {
    console.warn(
      'Providers.Auth:loadUser() | Api config is required to retreive customer information.');
  }

  state.isLoading = true;
  const url = `${config.api.uri}/${config.api.version}/users/login`;
  const headers = new Headers();

  headers.append('Content-Type', 'application/x-www-form-urlencoded');
  headers.append('Authorization', `Basic ${btoa(`${username}:${password}`)}`);

  const response = await Api.get(url, headers);
  state.isLoading = false;

  if (config.debug) {
    console.log('Providers.Auth:login():response data', response);
  }

  if (response.status === 'error') {
    setUser(null, config);
    return response;
  }
  setUser(response.data.data, config);
  document.dispatchEvent(
    new CustomEvent('koc-login', { detail: { user: response.data.data } }));

  if (config.debug) {
    console.log('Providers.Auth koc-login event dispatched.',
      { detail: { user: response.data.data } });
  }

  return response;
};

const logout = async (config) => {
  if (config.debug) {
    console.log('Providers.Auth:logout()');
  }
  document.dispatchEvent(new CustomEvent('koc-logout'));
  if (config.debug) {
    console.log('Providers.Auth koc-logout event dispatched.');
  }
  return setUser(null, config);
};

/**
 * @memberof Providers.Auth
 * @instance
 * @summary Attempts to register a user with the given user properties.
 * @param { string } firstname - The firstname.
 * @param { string } lastname - The lastname.
 * @param { string } email - The email.
 * @param { string } username - The username.
 * @param { string } password - The password.
 * @param { string } confirm - The confirm.
 * @param { Config } config - The component configuration.
 * @implements {@link Services.Api#post|Services.Api.post()}
 * @returns { Response }
 * @throws { Error }
 */
const register = async (
  firstname, lastname, email, username, password, confirm, config = {}) => {
  if (config.debug) {
    console.log('Providers.Auth:register():submitted data',
      {
        firstname,
        lastname,
        email,
        username,
        password,
        confirm,
        config,
      });
  }
  if (!config.api.uri) {
    console.warn(
      'Providers.Auth:loadUser() | Api config is required to retreive customer information.');
  }

  state.isLoading = true;
  const url = `${config.api.uri}/${config.api.version}/users/registration`;
  const headers = new Headers();

  headers.append('Accept', 'application/json');
  headers.append('Content-Type', 'application/json');

  const body = {
    firstName: firstname,
    lastName: lastname,
    userName: username,
    email,
    password,
  };

  const response = await Api.post(url, body, headers);
  state.isLoading = false;

  if (config.debug) {
    console.log('Providers.Auth:register():response data', response);
  }

  if (response.status === 'error') {
    setUser(null, config);
    return response;
  }
  setUser(response.data.data, config);

  if (response.data.data.redirect && response.data.data.redirect.url) {
    return window.location.replace(
      response.data.data.redirect.url);
  }

  return response;
};

/**
 * @memberof Providers.Auth
 * @instance
 * @summary Attempts to load a user with the provided id and token.
 * @param { string } id - The user id.
 * @param { string } token - The token.
 * @param { Config } config - The component configuration.
 * @implements {@link Services.Api#fetch|Services.Api.fetch()}
 * @returns { Response }
 * @throws { Error }
 */
const loadUser = async (id, token, config) => {
  if (config.debug) {
    console.log('Providers.Auth:loadUser():submitted data', {
      id,
      token,
      config,
    });
  }
  if (!config.api.uri) {
    console.warn(
      'Providers.Auth:loadUser() | Api config is required to retreive customer information.');
  }
  if (!token) {
    console.warn('Providers.Auth:loadUser() | User token is required.');
  }
  if (!id) {
    console.warn('Providers.Auth:loadUser() | Customer id is required.');
  }

  state.isLoading = true;
  const url = `${config.api.uri}/${config.api.version}/users/${id}?token=${token}&now=${Date.now()}`;
  const response = await Api.fetch(url);
  state.isLoading = false;

  if (config.debug) {
    console.log('Providers.Auth:loadUser():response data', response);
  }

  if (response.status === 'error') {
    setUser(null, config);
    return response;
  }
  setUser(response.data.data, config);

  return response;
};

/**
 * @memberof Providers.Auth
 * @instance
 * @summary Attempts to load a user's purchase history with the provided id and token.
 * @param { string } id - The user id.
 * @param { string } token - The token.
 * @param { Config } config - The component configuration.
 * @implements {@link Services.Api#fetch|Services.Api.fetch()}
 * @returns { Response }
 * @throws { Error }
 */
const loadUserPurchaseHistory = async (id, token, config) => {
  if (config.debug) {
    console.log('Providers.Auth:loadUserPurchaseHistory():submitted data', {
      id,
      token,
      config,
    });
  }
  if (!config.api.uri) {
    console.warn(
      'Providers.Auth:loadUserPurchaseHistory() | Api config is required to retreive customer information.');
  }
  if (!token) {
    console.warn('Providers.Auth:loadUserPurchaseHistory() | User token is required.');
  }
  if (!id) {
    console.warn('Providers.Auth:loadUserPurchaseHistory() | Customer id is required.');
  }

  state.isLoading = true;
  const url = `${config.api.uri}/${config.api.version}/users/${id}/purchase_history/?token=${token}&now=${Date.now()}`;

  const headers = new Headers();

  const response = await Api.get(url, headers);

  state.isLoading = false;

  if (config.debug) {
    console.log('Providers.Auth:loadUserPurchaseHistory():response data', response);
  }

  if (response.status === 'success') {
    state.purchaseHistory = response.data.data;
    if (config.debug) {
      console.log('Providers.Auth:loadUserPurchaseHistory():state.purchaseHistory data',
        state.purchaseHistory);
    }
  }

  return response;
};

/**
 * @memberof Providers.Auth
 * @instance
 * @summary Attempts to load a user's mailing address with the provided id and token.
 * @param { string } id - The user id.
 * @param { string } token - The token.
 * @param { Config } config - The component configuration.
 * @implements {@link Services.Api#fetch|Services.Api.fetch()}
 * @returns { Response }
 * @throws { Error }
 */
const loadUserMailingAddress = async (id, token, config) => {
  if (config.debug) {
    console.log('Providers.Auth:loadUserMailingAddress():submitted data', {
      id,
      token,
      config,
    });
  }
  if (!config.api.uri) {
    console.warn(
      'Providers.Auth:loadUserMailingAddress() | Api config is required to retreive user information.');
  }
  if (!token) {
    console.warn('Providers.Auth:loadUserMailingAddress() | User token is required.');
  }
  if (!id) {
    console.warn('Providers.Auth:loadUserMailingAddress() | User id is required.');
  }

  state.isLoading = true;
  const url = `${config.api.uri}/${config.api.version}/users/${id}/mailing_address/?token=${token}&now=${Date.now()}`;

  const response = await Api.fetch(url);
  state.isLoading = false;

  if (config.debug) {
    console.log('Providers.Auth:loadUserMailingAddress():response data', response);
  }

  if (response.status === 'success') {
    state.mailingAddress = response.data.data.mailing_address;
    if (config.debug) {
      console.log('Providers.Auth:loadUserMailingAddress():state.mailingAddress data',
        state.mailingAddress);
    }

  }

  return response;
};

/**
 * @memberof Providers.Auth
 * @instance
 * @summary Attempts to load a user's mailing address with the provided id and token.
 * @param { string } id - The user id.
 * @param { string } token - The token.
 * @param { Config } config - The component configuration.
 * @implements {@link Services.Api#fetch|Services.Api.fetch()}
 * @returns { Response }
 * @throws { Error }
 */
const updateUserMailingAddress = async (id, token, config, data) => {
  if (config.debug) {
    console.log('Providers.Auth:updateUserMailingAddress():submitted data', {
      id,
      token,
      config,
      data,
    });
  }
  if (!config.api.uri) {
    console.warn(
      'Providers.Auth:updateUserMailingAddress() | Api config is required to retreive user information.');
  }
  if (!token) {
    console.warn('Providers.Auth:updateUserMailingAddress() | User token is required.');
  }
  if (!id) {
    console.warn('Providers.Auth:updateUserMailingAddress() | User id is required.');
  }

  const body = {};
  body.mailing_address = data;
  state.isLoading = true;
  const url = `${config.api.uri}/${config.api.version}/users/${id}/mailing_address/?token=${token}`;
  const headers = new Headers();

  headers.append('Accept', 'application/json');
  headers.append('Content-Type', 'application/json');

  const response = await Api.post(url, body, headers);

  state.isLoading = false;

  if (config.debug) {
    console.log('Providers.Auth:updateUserMailingAddress():response data', response);
  }

  if (response.status === 'success') {
    state.mailingAddress = response.data.data.mailing_address;
  }

  return response;
};

/**
 * @memberof Providers.Auth
 * @instance
 * @summary Attempts to update a user's profile data with the provided id and token.
 * @param { string } id - The user id.
 * @param { string } token - The token.
 * @param { Config } config - The component configuration.
 * @implements {@link Services.Api#fetch|Services.Api.fetch()}
 * @returns { Response }
 * @throws { Error }
 */
const updateUserProfile = async (id, token, config, data) => {
  if (config.debug) {
    console.log('Providers.Auth:updateUserProfile():submitted data', {
      id,
      token,
      config,
      data,
    });
  }
  if (!config.api.uri) {
    console.warn(
      'Providers.Auth:updateUserProfile() | Api config is required to retreive user information.');
  }
  if (!token) {
    console.warn('Providers.Auth:updateUserProfile() | User token is required.');
  }
  if (!id) {
    console.warn('Providers.Auth:updateUserProfile() | User id is required.');
  }

  const body = {};
  body.profile = data;
  state.isLoading = true;
  const url = `${config.api.uri}/${config.api.version}/users/${id}/profile/?token=${token}`;
  const headers = new Headers();

  headers.append('Accept', 'application/json');
  headers.append('Content-Type', 'application/json');

  const response = await Api.post(url, body, headers);

  state.isLoading = false;

  if (config.debug) {
    console.log('Providers.Auth:updateUserProfile():response data', response);
  }

  if (response.status === 'success') {
    setUser(response.data.data, config);
  }

  return response;
};

/**
 * @memberof Providers.Auth
 * @instance
 * @summary Attempts to load a user's downloads with the provided id and token.
 * @param { string } id - The user id.
 * @param { string } token - The token.
 * @param { Config } config - The component configuration.
 * @implements {@link Services.Api#fetch|Services.Api.fetch()}
 * @returns { Response }
 * @throws { Error }
 */
const loadUserDownloads = async (id, token, config) => {
  if (config.debug) {
    console.log('Providers.Auth:loadUserDownloads():submitted data', {
      id,
      token,
      config,
    });
  }
  if (!config.api.uri) {
    console.warn(
      'Providers.Auth:loadUserDownloads() | Api config is required to retreive user information.');
  }
  if (!token) {
    console.warn('Providers.Auth:loadUserDownloads() | User token is required.');
  }
  if (!id) {
    console.warn('Providers.Auth:loadUserDownloads() | User id is required.');
  }

  state.isLoading = true;
  const url = `${config.api.uri}/${config.api.version}/users/${id}/downloads/?token=${token}`;

  const response = await Api.fetch(url);

  state.isLoading = false;

  if (config.debug) {
    console.log('Providers.Auth:loadUserDownloads():response data', response);
  }

  if (response.status === 'success') {
    state.downloads = response.data.data.downloads;
    if (config.debug) {
      console.log('Providers.Auth:loadUserDownloads():state.downloads data',
        state.downloads);
    }

  }

  return response;
};

/**
 * @memberof Providers.Auth
 * @instance
 * @summary Attempts to load a user's memberships with the provided id and token.
 * @param { string } id - The user id.
 * @param { string } token - The token.
 * @param { Config } config - The component configuration.
 * @implements {@link Services.Api#fetch|Services.Api.fetch()}
 * @returns { Response }
 * @throws { Error }
 */
const loadUserMemberships = async (id, token, config) => {
  if (config.debug) {
    console.log('Providers.Auth:loadUserMemberships():submitted data', {
      id,
      token,
      config,
    });
  }
  if (!config.api.uri) {
    console.warn(
      'Providers.Auth:loadUserMemberships() | Api config is required to retreive user information.');
  }
  if (!token) {
    console.warn('Providers.Auth:loadUserMemberships() | User token is required.');
  }
  if (!id) {
    console.warn('Providers.Auth:loadUserMemberships() | User id is required.');
  }

  state.isLoading = true;
  const url = `${config.api.uri}/${config.api.version}/users/${id}/memberships/?token=${token}`;

  const response = await Api.fetch(url);

  state.isLoading = false;

  if (config.debug) {
    console.log('Providers.Auth:loadUserMemberships():response data', response);
  }

  if (response.status === 'success') {
    state.memberships = response.data.data;
    if (config.debug) {
      console.log('Providers.Auth:loadUserMemberships():state.memberships data',
        state.memberships);
    }

  }

  return response;
};

/**
 * @memberof Providers.Auth
 * @instance
 * @summary Attempts to load a user's member profile data with the provided id and token.
 * @param { string } id - The user id.
 * @param { string } token - The token.
 * @param { Config } config - The component configuration.
 * @implements {@link Services.Api#fetch|Services.Api.fetch()}
 * @returns { Response }
 * @throws { Error }
 */
const loadUserMemberProfile = async (id, token, config) => {
  if (config.debug) {
    console.log('Providers.Auth:loadUserMemberProfile():submitted data', {
      id,
      token,
      config,
    });
  }
  if (!config.api.uri) {
    console.warn(
      'Providers.Auth:loadUserMemberProfile() | Api config is required to retreive user information.');
  }
  if (!token) {
    console.warn('Providers.Auth:loadUserMemberProfile() | User token is required.');
  }
  if (!id) {
    console.warn('Providers.Auth:loadUserMemberProfile() | User id is required.');
  }

  state.isLoading = true;
  const url = `${config.api.uri}/${config.api.version}/users/${id}/member_profile/?token=${token}&now=${Date.now()}`;

  const response = await Api.fetch(url);
  state.isLoading = false;

  if (config.debug) {
    console.log('Providers.Auth:loadUserMemberProfile():response data', response);
  }

  if (response.status === 'success') {
    state.memberProfile = response.data.data;
    if (config.debug) {
      console.log('Providers.Auth:loadUserMemberProfile():state.memberProfile data',
        state.memberProfile);
    }

  }

  return response;
};

/**
 * @memberof Providers.Auth
 * @instance
 * @summary Attempts to update a user's member profile data with the provided id and token.
 * @param { string } id - The user id.
 * @param { string } token - The token.
 * @param { Config } config - The component configuration.
 * @implements {@link Services.Api#fetch|Services.Api.fetch()}
 * @returns { Response }
 * @throws { Error }
 */
const updateUserMemberProfile = async (id, token, config, data) => {
  if (config.debug) {
    console.log('Providers.Auth:updateUserMemberProfile():submitted data', {
      id,
      token,
      config,
      data,
    });
  }
  if (!config.api.uri) {
    console.warn(
      'Providers.Auth:updateUserMemberProfile() | Api config is required to retreive user information.');
  }
  if (!token) {
    console.warn('Providers.Auth:updateUserMemberProfile() | User token is required.');
  }
  if (!id) {
    console.warn('Providers.Auth:updateUserMemberProfile() | User id is required.');
  }

  state.isLoading = true;
  const url = `${config.api.uri}/${config.api.version}/users/${id}/member_profile/?token=${token}`;
  const headers = new Headers();

  headers.append('Accept', 'application/json');
  headers.append('Content-Type', 'application/json');

  const response = await Api.post(url, data, headers);

  state.isLoading = false;

  if (config.debug) {
    console.log('Providers.Auth:updateUserMemberProfile():response data', response);
  }

  if (response.status === 'success') {
    state.memberProfile = response.data.data;
    if (config.debug) {
      console.log('Providers.Auth:updateUserMemberProfile():state.memberProfile data',
        state.memberProfile);
    }
  }

  return response;
};

/**
 * @memberof Providers.Auth
 * @instance
 * @summary Initiates an api call to send an email for password reset using the provided username and config.
 * @param { string } id - The username or email.
 * @param { Config } config - The component configuration.
 * @implements {@link Services.Api#fetch|Services.Api.fetch()}
 * @returns { Response }
 * @throws { Error }
 */
const initiatePasswordReset = async (username, config) => {
  if (config.debug) {
    console.log('Providers.Auth:initiatePasswordReset():submitted data', {
      username, config
    });
  }
  if (!config.api.uri) {
    console.warn(
      'Providers.Auth:initiatePasswordReset() | Api config is required to retreive user information.');
  }
  if (!username) {
    console.warn('Providers.Auth:initiatePasswordReset() | User token is required.');
  }

  let data = {};
  data.email = username;

  state.isLoading = true;
  const url = `${config.api.uri}/${config.api.version}/accounts/lost-password`;

  const headers = new Headers();

  headers.append('Accept', 'application/json');
  headers.append('Content-Type', 'application/json');

  const response = await Api.post(url, data, headers);

  state.isLoading = false;

  if (config.debug) {
    console.log('Providers.Auth:initiatePasswordReset():response data', response);
  }

  return response;
};

/**
 * @memberof Providers.Auth
 * @instance
 * @summary Initiates an api call to get the greeting to show user during password reset using the provided token and config.
 * @param { token } id - The reset token.
 * @param { Config } config - The component configuration.
 * @implements {@link Services.Api#fetch|Services.Api.fetch()}
 * @returns { Response }
 * @throws { Error }
 */
const getPasswordResetGreeting = async (token, config) => {
  if (config.debug) {
    console.log('Providers.Auth:getPasswordResetGreeting():submitted data', {
      token, config
    });
  }
  if (!config.api.uri) {
    console.warn(
      'Providers.Auth:getPasswordResetGreeting() | Api config is required to retreive user information.');
  }
  if (!token) {
    console.warn('Providers.Auth:getPasswordResetGreeting() | User token is required.');
  }

  const url = `${config.api.uri}/${config.api.version}/accounts/reset-password?password_reset_key=${token}`;

  const response = await Api.fetch(url);

  if (config.debug) {
    console.log('Providers.Auth:getPasswordResetGreeting():response data', response);
  }

  return response;
};

/**
 * @memberof Providers.Auth
 * @instance
 * @summary Sends an api call to reset password with new password and verification token using the provided token and config.
 * @param { token } id - The reset token.
 * @param { Config } config - The component configuration.
 * @implements {@link Services.Api#fetch|Services.Api.fetch()}
 * @returns { Response }
 * @throws { Error }
 */
const submitPasswordReset = async (data, config) => {
  if (config.debug) {
    console.log('Providers.Auth:submitPasswordReset():submitted data', {
      data, config
    });
  }
  if (!config.api.uri) {
    console.warn(
      'Providers.Auth:submitPasswordReset() | Api config is required to retreive user information.');
  }
  if (!data) {
    console.warn('Providers.Auth:submitPasswordReset() | Reset Data is required.');
  }

  const url = `${config.api.uri}/${config.api.version}/accounts/reset-password`;

  const headers = new Headers();

  headers.append('Accept', 'application/json');
  headers.append('Content-Type', 'application/json');

  const response = await Api.post(url, data, headers);

  if (config.debug) {
    console.log('Providers.Auth:submitPasswordReset():response data', response);
  }

  return response;
};

const sendUserActivity = async (user, data, config) => {
  if (config.debug) {
    console.log('Providers.Auth:sendUserActivity():submitted data', {
      data, config
    });
  }
  if (!config.api.uri) {
    console.warn(
      'Providers.Auth:sendUserActivity() | Api config is required to send user activity.');
  }
  if (!data) {
    console.warn('Providers.Auth:sendUserActivity() | Activity Data is required.');
  }

  data.kol_token = user.token;

  const url = `${config.api.uri}/${config.api.version}/users/${user.id}/activity`;

  const headers = new Headers();

  headers.append('Accept', 'application/json');
  headers.append('Content-Type', 'application/json');

  const response = await Api.post(url, data, headers);

  if (config.debug) {
    console.log('Providers.Auth:sendUserActivity():response data', response);
  }

  return response;
};


/**
 * KelbyOne Auth Provider
 * @namespace Providers.Auth
 * @ignore
 * @summary KelbyOne Auth Provider
 * @property { Boolean } isLoading General loading state.
 * @property { Boolean } isCreating General creating state.
 * @property { Boolean } isUpdating General updating state.
 * @property { Object | null } getUser Returns the state of the user object.
 * @property { Boolean } hasUser Returns whether a user exists.
 */
export default function AuthProvider() {
  return readonly({
    login,
    logout,
    register,
    loadUser,
    setUser,
    loadUserCookie,
    deleteUserCookie,
    loadUserPurchaseHistory,
    loadUserMailingAddress,
    updateUserMailingAddress,
    updateUserProfile,
    loadUserDownloads,
    loadUserMemberships,
    loadUserMemberProfile,
    updateUserMemberProfile,
    initiatePasswordReset,
    getPasswordResetGreeting,
    submitPasswordReset,
    sendUserActivity,
    // General
    isLoading: computed(() => Boolean(state.isLoading)),
    isCreating: computed(() => Boolean(state.isCreating)),
    isUpdating: computed(() => Boolean(state.isUpdating)),
    // User
    getUser: computed(() => state.user),
    hasUser: computed(() => Boolean(state.user)),
    // User cookie
    getUserCookie: computed(() => state.cookie),
    hasUserCookie: computed(() => Boolean(state.cookie)),
    // User Data
    getUserPurchaseHistory: computed(() => state.purchaseHistory),
    hasUserPurchaseHistory: computed(() => Boolean(state.purchaseHistory)),
    getUserMailingAddress: computed(() => state.mailingAddress),
    hasUserMailingAddress: computed(() => Boolean(state.mailingAddress)),
    getUserDownloads: computed(() => state.downloads),
    hasUserDownloads: computed(() => Boolean(state.downloads)),
    getUserMemberships: computed(() => state.memberships),
    hasUserMemberships: computed(() => Boolean(state.memberships)),
    getUserMemberProfile: computed(() => state.memberProfile),
    hasUserMemberProfile: computed(() => Boolean(state.memberProfile)),
    //completed lessons
    getCompletedLessons: computed(() => state.userCompletedLessons),
  })

}
