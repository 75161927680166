import { reactive, readonly } from 'vue'

import { Api } from '@/services/api.service'

const state = reactive({})

const toggleBookmark = async (content, member, bookmarked, token, config) => {
  if (config.debug) {
    console.log('Providers.Member:toggleBookmark():submitted data', {
      content,
      member,
      bookmarked,
      token,
      config,
    })
  }
  if (!content) {
    throw Error(
      'Providers.Member:toggleBookmark() | Content ID is required to toggle bookmark.')
  }
  if (!member) {
    throw Error(
      'Providers.Member:toggleBookmark() | Member ID is required to toggle bookmark.')
  }
  if (!token) {
    throw Error(
      'Providers.Member:toggleBookmark() | Token is required to toggle bookmark.')
  }
  if (!config.api.uri) {
    throw Error(
      'Providers.Member:toggleBookmark() | Api config is required to toggle bookmark.')
  }

  let url = `${config.api.uri}/${config.api.version}/users/${member}/content/${content}?token=${token}&now=${Date.now()}`

  const headers = new Headers()

  headers.append('Accept', 'application/json')
  headers.append('Content-Type', 'application/json')

  const details = {
    bookmarked,
  }

  const response = await Api.post(url, details, headers)

  if (config.debug) {
    console.log('Providers.Member:toggleBookmark():response', response)
  }

  return response
}

const toggleArchive = async (content, member, archived, token, config) => {
  if (config.debug) {
    console.log('Providers.Member:toggleArchive():submitted data', {
      content,
      member,
      archived,
      token,
      config,
    })
  }
  if (!content) {
    throw Error(
      'Providers.Member:toggleArchive() | Content ID is required to toggle bookmark.')
  }
  if (!member) {
    throw Error(
      'Providers.Member:toggleArchive() | Member ID is required to toggle bookmark.')
  }
  if (!token) {
    throw Error(
      'Providers.Member:toggleArchive() | Token is required to toggle bookmark.')
  }
  if (!config.api.uri) {
    throw Error(
      'Providers.Member:toggleArchive() | Api config is required to toggle bookmark.')
  }

  let url = `${config.api.uri}/${config.api.version}/users/${member}/content/${content}?token=${token}&now=${Date.now()}`

  const headers = new Headers()

  headers.append('Accept', 'application/json')
  headers.append('Content-Type', 'application/json')

  const details = {
    archived,
  }

  const response = await Api.post(url, details, headers)

  if (config.debug) {
    console.log('Providers.Member:toggleArchive():response', response)
  }

  return response
}

export default function MemberProvider () {
  return readonly({
    toggleBookmark,
    toggleArchive,
  })
}
