import { Api } from '@/services/api.service';

/**
 * @memberof Providers.Tracking
 * @instance
 * @summary Handles all tracking.
 * @param { String } key - The tracking name.
 * @param { object } data - The tracking data.
 * @param { Config } config - The component configuration.
 * @returns { void }
 */
export const Tracking = {
  track: async (key, data) => {
    const url = `https://members.kelbyone.com/wp-json/ko/v3/logs/${key}`;

    const body = JSON.stringify(data);

    const headers = new Headers();
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    const response = await Api.post(url, body, headers);
    return await response;
  }
}
