import { computed, reactive, readonly, ref, } from 'vue';

import { Api } from '@/services/api.service';

const state = reactive({
  isLoading: ref(false),
  isUploading: ref(false),
  isUpdating: ref(false),
  gallery: ref({}),
  images: ref([]),
  pagination: ref(null),
  filters: ref(null),
});

/**
 * @memberof Providers.Galleries
 * @instance
 * @summary Attempts to load a gallery with the gallery id.
 * @param { string } id - The gallery id.
 * @param { Config } config - The component configuration.
 * @implements {@link Services.Api#fetch|Services.Api.fetch()}
 * @returns { Response }
 * @throws { Error }
 */
const loadGallery = async (id, config) => {
  if (config.debug) {
    console.log('Providers.Galleries:loadGallery():submitted data', { id, config });
  }
  if (!config.api.uri) {
    throw Error(
      'Providers.Galleries:loadGallery() | Api config is required to retreive gallery information.');
  }
  if (!id) {
    throw Error('Providers.Galleries:loadGallery() | Gallery id is required.');
  }

  state.isLoading = true;
  const url = `${config.api.uri}/${config.api.version}/galleries/${id}`;
  const response = await Api.fetch(url);
  state.isLoading = false;

  if (config.debug) {
    console.log('Providers.Galleries:loadGallery():response data', response);
  }

  if (response.status === 'error') {
    state.gallery = {};
    return response;
  }

  state.gallery = response.data.data;

  if (config.debug) {
    console.log('Providers.Galleries:loadGallery():state gallery', state.gallery);
  }

  return response;
};

/**
 * @memberof Providers.Galleries
 * @instance
 * @summary Upload image to a gallery.
 * @param { string } id - The gallery id.
 * @param { image } image - The image.
 * @param { user } user - The user.
 * @param { Config } config - The component configuration.
 * @param { string } title - The image title.
 * @param { string } description - The image description.
 * @param { array } categories - The image categories.
 * @implements {@link Services.Api#fetch|Services.Api.fetch()}
 * @returns { Response }
 * @throws { Error }
 */
const uploadImage = async (id, image, user, config, title, description, categories, livestreamEmail = null) => {
  if (config.debug) {
    console.log('Providers.Galleries:uploadImage():submitted data',
      { id, image, user, title, description, categories, config });
  }
  if (!config.api.uri) {
    throw Error(
      'Providers.Galleries:uploadImage() | Api config is required to upload gallery image.');
  }
  if (!id) {
    throw Error('Providers.Galleries:uploadImage() | Gallery id is required.');
  }
  if (!image) {
    throw Error('Providers.Galleries:uploadImage() | Image is required.');
  }
  if (!user) {
    throw Error('Providers.Galleries:uploadImage() | User id is required.');
  }

  const formData = new FormData();
  formData.append('file', image);
  formData.append('title', title);
  if (description) {
    formData.append('description', description);
  }
  formData.append('user_id', user);
  formData.append('categories', categories);
  if (livestreamEmail) {
    formData.append('livestream_email', livestreamEmail);
  }

  state.isLoading = true;

  let url = `${config.api.uri}/${config.api.version}/galleries/${id}/images`;
  const headers = new Headers();

  const response = await Api.post(url, formData, headers, true);

  state.isLoading = false;

  if (config.debug) {
    console.log('Providers.Galleries:uploadImage():response data', response);
  }

  return response;

};

/**
 * @memberof Providers.Galleries
 * @instance
 * @summary Load images from api.
 * @param { Config } config - The component configuration.
 * @param { object } params - status, sort_by, sort_order, page, per_page options for image api results.
 * @param { array } users - user ids to filter image api results.
 * @param { array } galleries - gallery ids to filter image api results.
 * @param { array } categories - category ids to filter image api results.
 * @param { string } token - token to add user likes to image api results.
 * @implements {@link Services.Api#post|Services.Api.post()}
 * @returns { Response }
 * @throws { Error }
 */

const loadImages = async (config, params = {}, users, galleries, categories, token) => {
  if (config.debug) {
    console.log('Providers.Galleries:loadImages():submitted data', {
      config,
      params,
      users,
      galleries,
      categories,
      token,
    });
  }
  if (!config.api.uri) {
    throw Error('Providers.Galleries:loadImages() | Api config is required to upload gallery image.');
  }

  state.isLoading = true;

  let url = `${config.api.uri}/${config.api.version}/images/`;

  const headers = new Headers();
  headers.append('Content-Type', 'application/json');

  let args = {};
  args.status = params.status || 'publish';
  args.sort_order = params.sort_order || 'desc';
  args.page = params.page || 1;
  args.per_page = params.per_page || 10;
  if (params.sort_by) {
    args.sort_by = params.sort_by;
  }

  if (users) {
    args.users = users;
  }
  if (galleries) {
    args.galleries = galleries;
  }
  if (categories) {
    args.categories = categories;
  }
  if (params.categories) {
    args.categories = params.categories;
  }
  if (token) {
    args.token = token;
  }
  if (params.token) {
    args.token = params.token;
  }
  if (params.gallery_id) {
    args.gallery_id = params.gallery_id;
  }
  if (params.user_id) {
    args.user_id = params.user_id;
  }

  if (config.debug) {
    console.log('Providers.Galleries:loadImages():Api.post()', url, args, headers);
  }

  const response = await Api.post(url, args, headers);

  state.isLoading = false;

  if (config.debug) {
    console.log('Providers.Galleries:loadImages():response data', response);
  }

  if (response.status === 'error') {
    state.images = [];
    return response;
  }

  state.images = response.data.data;
  state.pagination = null;
  // state.filters = null;
  if (response.data.meta) {
    if (response.data.meta.pagination) {
      state.pagination = response.data.meta.pagination;
    }
    if (response.data.meta.filters) {
      console.log('setting filters', response.data.meta.filters);
      state.filters = response.data.meta.filters;
    }
  }

  if (config.debug) {
    console.log('Providers.Galleries:loadImages():state images', state.images);
    console.log('Providers.Galleries:loadImages():state pagination', state.pagination);
  }

  return response;
};

const loadImage = async (id, config) => {
  if (config.debug) {
    console.log('Providers.Galleries:loadImage():submitted data', { id, config });
  }
  if (!config.api.uri) {
    throw Error('Providers.Galleries:loadImage() | Api config is required to get image.');
  }
  if (!id) {
    throw Error('Providers.Galleries:loadImage() | Image ID is required to get image.');
  }

  state.isLoading = true;

  let url = `${config.api.uri}/${config.api.version}/images/${id}?now=${Date.now()}`;

  const headers = new Headers();
  headers.append('Content-Type', 'application/json');

  let response = await Api.fetch(url);

  state.isLoading = false;

  if (config.debug) {
    console.log('Providers.Galleries:loadImage():response data', response);
  }

  const comments = await loadImageComments(id, config);
  const item = { ...response.data.data, comments: comments.data.data };
  return item;

};

const loadImageComments = async (id, config) => {
  if (config.debug) {
    console.log('Providers.Galleries:loadImageComments():submitted data', { id, config });
  }
  if (!config.api.uri) {
    throw Error(
      'Providers.Galleries:loadImageComments() | Api config is required to get image comments.');
  }
  if (!id) {
    throw Error(
      'Providers.Galleries:loadImageComments() | Image ID is required to get image comments.');
  }

  state.isLoading = true;

  let url = `${config.api.uri}/${config.api.version}/images/${id}/comments?now=${Date.now()}`;

  const headers = new Headers();
  headers.append('Content-Type', 'application/json');

  const response = await Api.fetch(url);

  state.isLoading = false;

  if (config.debug) {
    console.log('Providers.Galleries:loadImageComments():response data', response);
  }

  return response;
};

const updateImageLike = async (user, token, image, like, config) => {
  if (config.debug) {
    console.log('Providers.Galleries:updateImageLike():submitted data',
      { user, token, image, like, config });
  }
  if (!config.api.uri) {
    throw Error(
      'Providers.Galleries:updateImageLike() | Api config is required to update image like.');
  }
  if (!user) {
    throw Error('Providers.Galleries:updateImageLike() | User ID is required to update image like.');
  }
  if (!token) {
    throw Error(
      'Providers.Galleries:updateImageLike() | User auth token is required to update image like.');
  }
  if (!image) {
    throw Error('Providers.Galleries:updateImageLike() | Image ID is required to update image like.');
  }
  if (typeof like !== 'boolean') {
    throw Error(
      'Providers.Galleries:updateImageLike() | Like param must be Boolean to update image like.');
  }

  const start = new CustomEvent('koc:gallery:updateLike', { user, token, image, like });
  document.dispatchEvent(start);

  let args = {};
  args.liked = like;
  args.token = token;

  state.isLoading = true;

  let url = `${config.api.uri}/${config.api.version}/users/${user}/images/${image}/likes`;

  const headers = new Headers();
  headers.append('Content-Type', 'application/json');

  const response = await Api.post(url, args, headers);

  state.isLoading = false;

  if (config.debug) {
    console.log('Providers.Galleries:updateImageLike():response data', response);
  }

  const complete = new CustomEvent('koc:gallery:updateLike:response',
    { detail: response.data.data });
  document.dispatchEvent(complete);

  return response;
};

const createImageComment = async (user, token, image, content, config) => {
  if (config.debug) {
    console.log('Providers.Galleries:createImageComment():submitted data',
      { user, token, image, content, config });
  }
  if (!config.api.uri) {
    throw Error(
      'Providers.Galleries:createImageComment() | Api config is required to create image comment.');
  }
  if (!user) {
    throw Error(
      'Providers.Galleries:createImageComment() | User ID is required to create image comment.');
  }
  if (!token) {
    throw Error(
      'Providers.Galleries:createImageComment() | User Auth token is required to create image comment.');
  }
  if (!image) {
    throw Error(
      'Providers.Galleries:createImageComment() | Image ID is required to create image comment.');
  }
  let trimmed = content.trim();
  if (!trimmed) {
    throw Error(
      'Providers.Galleries:createImageComment() | content is required to create image comment.');
  }

  let args = {};
  args.content = trimmed;
  args.token = token;

  state.isLoading = true;

  let url = `${config.api.uri}/${config.api.version}/users/${user}/images/${image}/comments`;

  const headers = new Headers();
  headers.append('Content-Type', 'application/json');

  const response = await Api.post(url, args, headers);

  state.isLoading = false;

  if (config.debug) {
    console.log('Providers.Galleries:createImageComment():response data', response);
  }

  return response;
};

const deleteImageComment = async (user, token, image, comment, config) => {
  if (config.debug) {
    console.log('Providers.Galleries:deleteImageComment():submitted data',
      { user, token, image, comment, config });
  }
  if (!config.api.uri) {
    throw Error(
      'Providers.Galleries:deleteImageComment() | Api config is required to delete image comment.');
  }
  if (!user) {
    throw Error(
      'Providers.Galleries:deleteImageComment() | User ID is required to delete image comment.');
  }
  if (!token) {
    throw Error(
      'Providers.Galleries:deleteImageComment() | User auth token is required to delete image comment.');
  }
  if (!image) {
    throw Error(
      'Providers.Galleries:deleteImageComment() | Image ID is required to delete image comment.');
  }
  if (!comment) {
    throw Error(
      'Providers.Galleries:deleteImageComment() | Comment ID is required to delete image comment.');
  }

  state.isLoading = true;

  let url = `${config.api.uri}/${config.api.version}/users/${user}/images/${image}/comments/${comment}?token=${token}`;

  const response = await Api.remove(url);

  state.isLoading = false;

  if (config.debug) {
    console.log('Providers.Galleries:deleteImageComment():response data', response);
  }

  return response;
};

const deleteImage = async (user, token, image, config) => {
  if (config.debug) {
    console.log('Providers.Galleries:deleteImage():submitted data',
      { user, token, image, config });
  }
  if (!config.api.uri) {
    throw Error(
      'Providers.Galleries:deleteImage() | Api config is required to delete image.');
  }
  if (!user) {
    throw Error(
      'Providers.Galleries:deleteImage() | User ID is required to delete image.');
  }
  if (!token) {
    throw Error(
      'Providers.Galleries:deleteImage() | User auth token is required to delete image.');
  }
  if (!image) {
    throw Error(
      'Providers.Galleries:deleteImage() | Image ID is required to delete image.');
  }

  state.isLoading = true;

  let url = `${config.api.uri}/${config.api.version}/users/${user}/images/${image}?token=${token}`;

  const response = await Api.remove(url);

  state.isLoading = false;

  if (config.debug) {
    console.log('Providers.Galleries:deleteImage():response data', response);
  }

  return response;
};

export default function GalleriesProvider() {
  return readonly({
    loadGallery,
    uploadImage,
    loadImages,
    loadImage,
    loadImageComments,
    updateImageLike,
    createImageComment,
    deleteImageComment,
    deleteImage,
    // General
    isLoading: computed(() => Boolean(state.isLoading)),
    isUploading: computed(() => Boolean(state.isUploading)),
    isUpdating: computed(() => Boolean(state.isUpdating)),
    // Images
    getImages: computed(() => state.images),
    hasImages: computed(() => Boolean(state.images.length)),
    // Gallery
    getGallery: computed(() => state.gallery),
    hasGallery: computed(() => Boolean(state.gallery)),
    // Pagination
    getPagination: computed(() => state.pagination),
    hasPagination: computed(() => Boolean(state.pagination)),
    // Filters
    getFilters: computed(() => state.filters),
    hasFilters: computed(() => Boolean(state.filters)),
  });
}
